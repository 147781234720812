<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the reaction shown below along with its rate law:</p>

      <p class="mb-5 pl-14">
        <stemble-latex
          content="$\ce{3 A(g) + 2 B(g) -> 2 C(g)}\qquad\text{rate = k[A]}^2\text{[B]}$"
        />
      </p>

      <p class="mb-0">a) What is the order of the reaction with respect to reagent A?</p>

      <p>
        <v-select
          v-model="inputs.orderA"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select Order"
          class="mb-0 mt-0 ml-4"
          style="width: 100px"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-0">b) What is the order of the reaction with respect to reagent B?</p>

      <p>
        <v-select
          v-model="inputs.orderB"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select Order"
          class="mb-0 ml-4"
          style="width: 100px"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-0">c) What is the overall reaction order?</p>

      <p>
        <v-select
          v-model="inputs.rxnOrder"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select Order"
          class="mb-0 ml-4"
          style="width: 100px"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        d) Determine the initial rate of the reaction if you start with
        <number-value :value="concA" unit="\text{M}" /> of
        <stemble-latex content="$\text{A}$" /> and
        <number-value :value="concB" unit="\text{M}" /> of
        <stemble-latex content="$\text{B}$" />
        and the reaction has a rate constant of
        <number-value :value="rateConstant" unit="\text{M}^{-2}\text{s}^{-1}\text{.}" />
      </p>

      <calculation-input
        v-model="inputs.rate"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question366',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        orderA: null,
        orderB: null,
        rxnOrder: null,
        rate: null,
      },
      items: [
        {
          text: '0<sup>th</sup>',
          value: '0',
        },
        {
          text: '1<sup>st</sup>',
          value: '1',
        },
        {
          text: '2<sup>nd</sup>',
          value: '2',
        },
        {
          text: '3<sup>rd</sup>',
          value: '3',
        },
        {
          text: '4<sup>th</sup>',
          value: '4',
        },
        {
          text: '5<sup>th</sup>',
          value: '5',
        },
      ],
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    concB() {
      return this.taskState.getValueBySymbol('concB').content;
    },
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
  },
};
</script>
